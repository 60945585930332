import React, { Fragment } from "react";
import {
    BulkDeleteButton,
    Create,
    Datagrid,
    DateField,
    DeleteButton,
    Edit,
    EditButton,
    List,
    ListButton,
    maxLength,
    minLength,
    RefreshButton,
    required,
    SaveButton,
    SimpleForm,
    TextField,
    TextInput,
    Toolbar,
    TopToolbar,
    FileInput,
    FileField,
    ReferenceInput,
    SelectInput,
} from "react-admin";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
    toolbar: {
        display: "flex",
        justifyContent: "space-between",
    },
});

const validateRequired = [required()];

const validateNamespaceName = [required(), minLength(3), maxLength(64)];
const validateLocale = [required(), minLength(3), maxLength(32)];

const PostEditActions = ({ basePath }) => (
    <TopToolbar>
        <ListButton basePath={basePath} />
        <RefreshButton />
    </TopToolbar>
);

const CustomToolbar = (props) => (
    <Toolbar {...props} classes={useStyles()}>
        <SaveButton />
    </Toolbar>
);

const CodeBulkActionButtons = (props) => (
    <Fragment>
        <BulkDeleteButton {...props} />
    </Fragment>
);

export const CustomDeleteButton = (props) => (
    <DeleteButton {...props} mutationMode="pessimistic" />
);

export const NamespaceList = (props) => (
    <List {...props} bulkActionButtons={<CodeBulkActionButtons />}>
        <Datagrid rowClick="edit">
            <TextField label="ID" source="id" />
            <TextField label="Name" source="name" />
            <TextField label="Locale" source="languageId" />
            <TextField label="Format" source="format" />
            <TextField label="Platform" source="platform" />
            <DateField label="Date Created" source="dateCreated" showTime />
            <DateField label="Date Modified" source="dateModified" showTime />
            <EditButton />
            <CustomDeleteButton />
        </Datagrid>
    </List>
);

export const NamespaceCreate = (props) => (
    <Create actions={<PostEditActions />} {...props}>
        <SimpleForm redirect="list">
            <ReferenceInput
                label="Select Language ID"
                source="languageId"
                reference="Language">
                <SelectInput  optionText="name" validate={validateRequired} />
            </ReferenceInput>
            <TextInput
                label="Language Name"
                source="name"
                validate={validateNamespaceName}
            />
            <TextInput
                label="Format"
                source="format"
                validate={validateNamespaceName}
            />
            <TextInput
                label="Platform"
                source="platform"
                validate={validateNamespaceName}
            />
            <FileInput source="file" label="Language File" accept=".xml">
                <FileField source="src" title="title" />
            </FileInput>
        </SimpleForm>
    </Create>
);

export const NamespaceEdit = (props) => (
    <Edit actions={<PostEditActions />} {...props}>
        <SimpleForm toolbar={<CustomToolbar />}>
            <TextInput
                label="Language Name"
                source="name"
                validate={validateNamespaceName}
            />
            <TextInput
                label="Locale"
                source="locale"
                validate={validateLocale}
            />
        </SimpleForm>
    </Edit>
);

import * as React from "react";
import { AppBar } from "react-admin";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    title: {
        flex: 1,
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
    },
    spacer: {
        flex: 1,
    },
});

const MyAppBar = (props) => {
    const classes = useStyles();
    return (
        <AppBar {...props} style={{ backgroundColor: "#FDCF48" }}>
            <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
                id="react-admin-title"
            />
            <span className={classes.spacer} />
        </AppBar>
    );
};

export default MyAppBar;

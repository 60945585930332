import React, { Fragment } from 'react';
import {
    BulkDeleteButton,
    Create,
    Datagrid,
    DateField,
    DeleteButton,
    Edit,
    EditButton,
    List,
    ListButton,
    maxLength,
    minLength,
    RefreshButton,
    required,
    SaveButton,
    SimpleForm,
    TextField,
    TextInput,
    Toolbar,
    TopToolbar,
} from 'react-admin'
import { makeStyles } from '@mui/styles';


const useStyles = makeStyles({
    toolbar: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  });

const validateLanguageName = [required(), minLength(3), maxLength(64)];
const validateLocale = [required(), minLength(3), maxLength(32)];

const PostEditActions = ({ basePath }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
    <RefreshButton />
  </TopToolbar>
);

const CustomToolbar = props => (
    <Toolbar {...props} classes={useStyles()}>
      <SaveButton />
    </Toolbar>
);
  
const CodeBulkActionButtons = props => (
    <Fragment>
    <BulkDeleteButton {...props} />
  </Fragment>
);

export const CustomDeleteButton = props => (
  <DeleteButton {...props} mutationMode="pessimistic" />
);

export const LanguageList = props => (
  <List {...props} bulkActionButtons={<CodeBulkActionButtons />}>
    <Datagrid rowClick="edit">
        <TextField label="Language ID" source="id" />
        <TextField label="Name" source="name" />
        <TextField label="Locale" source="locale" />
        <DateField label="Date Created" source="dateCreated" showTime />
      <DateField label="Date Modified" source="dateModified" showTime />
      <EditButton />
      <CustomDeleteButton />
    </Datagrid>
  </List>
);

export const LanguageCreate = props => (
  <Create actions={<PostEditActions />} {...props}>
    <SimpleForm redirect="list">
      <TextInput
        label="Language Name"
        source="name"
        validate={validateLanguageName}
      />
      <TextInput 
        label="Locale"
        source="locale"
        validate={validateLocale} />
    </SimpleForm>
  </Create>
);

export const LanguageEdit = props => (
    <Edit actions={<PostEditActions />} {...props}>
      <SimpleForm toolbar={<CustomToolbar />}>
        <TextInput
          label="Language Name"
          source="name"
          validate={validateLanguageName}
        />
        <TextInput 
            label="Locale"
            source="locale"
            validate={validateLocale}
        />
      </SimpleForm>
    </Edit>
  );
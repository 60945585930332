import { Config } from '../Constants/Config';

const AuthProvider = {
    login: ({ username, password }) => {
        var clientId = Config.clientId;
        var clientSecret = Config.clientSecret;
        var basicAuth = 'Basic ' + btoa(clientId + ':' + clientSecret);
        let formData = new FormData();
        formData.append('username', username);
        formData.append('password', password);
        formData.append('grant_type', 'password');
        const request = new Request(Config.a3sUrl + '/oauth/token', {
            method: 'POST',
            body: formData,
            headers: new Headers({ Authorization: basicAuth }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(({ access_token }) => {
                localStorage.setItem('access_token', access_token);
            });
    },
    logout: () => {
        localStorage.removeItem('access_token');
        return Promise.resolve();
    },
    checkError: ({ status }) => {
        console.log(status)
        if (status === 401 || status === 403) {
            localStorage.removeItem('access_token');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    checkAuth: () => {
        return localStorage.getItem('access_token')
            ? Promise.resolve()
            : Promise.reject();
    },
    getPermissions: () => Promise.resolve(),
    getIdentity: () => {
        try {
            const { id, fullName, avatar } = { id: "", fullName: "TAMS Admin", avatar: "" }
            return Promise.resolve({ id, fullName, avatar });
        } catch (error) {
            return Promise.reject(error);
        }
    }
};

export default AuthProvider;
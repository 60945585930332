import React from 'react';
import {Config} from './Constants/Config';
import {Admin, Resource, fetchUtils} from 'react-admin';
import { LanguageList, LanguageCreate, LanguageEdit } from "./Language/LanguageList";
import { NamespaceList, NamespaceCreate, NamespaceEdit } from "./Namespace/NamespaceList";
import { ProjectsList } from "./Projects/Projects";
import jsonServerProvider from './Providers/dataProvider';
import authProvider from './Providers/authProvider.js';
import LanguageIcon from '@mui/icons-material/Language';
import BadgeIcon from '@mui/icons-material/Badge';
import MyLayout from "./Layout/MyLayout";
import MyLogin from "./Layout/MyLogin";
import MyLogoutButton from './Layout/MyLogout';

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({Accept: 'application/json'});
  }
  const token = localStorage.getItem('access_token');
  options.headers.set('Authorization', `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};

const dataProvider = jsonServerProvider(Config.tamsUrl, httpClient);

const App = () => {

  if (!dataProvider) {
    return (
      <div className="loader-container">
        <div className="loader">Loading...</div>
      </div>
    );
  }

    return (
      <Admin
        layout={MyLayout}
        loginPage={MyLogin}
        logoutButton={MyLogoutButton}
        authProvider={authProvider}
        dataProvider={dataProvider}>
        <Resource
          name="Language"
          list={LanguageList}
          create={LanguageCreate}
          edit={LanguageEdit}
          icon={LanguageIcon} />
        <Resource
          name="Namespace"
          list={NamespaceList}
          create={NamespaceCreate}
          edit={NamespaceEdit}
          icon={BadgeIcon} />
        <Resource
          name="projects"
          list={ProjectsList}
           />
      </Admin>
    );
};


export default App;